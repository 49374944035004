.header-h2 {
    font-weight: 200;
    font-size: 2.5rem;
}

.header-h1 {
    color : var(--color-blue);
    font-weight: 900;
    font-size: 4rem;
    text-transform: uppercase;
}

.block {
    height: 1200px;
    min-height: 100%;
}

.margin {
    margin-bottom: 5rem;
}

.wiooh-bg-1 {
    position: relative;
}

.wiooh-bg-1 img{
    position: absolute;
    width: 100%;
    bottom:-390px;
    left: 0;
    z-index: -1;
}

@media (max-width: 1200px) {
    .header-h2 {
        font-size: 2.5rem;
    }
    
    .header-h1 {
        font-size: 4rem;
    }
    .block {
        height: 900px;
    }
    /* #incentives.block {
        height: 1700px !important;
    } */
    .wiooh-bg-1 img{
        bottom:-220px;
    }
}

@media (max-width: 992px) {
    .block {
        height: 700px;
    }
    #solution.block {
        height: 800px;
    }
    .wiooh-bg-1 img{
        bottom:-200px;
    }
    /* #incentives.block {
        height: 700px !important;
    } */
}
@media (max-width: 768px) {
    .block {
        height: 650px;
    }
    .wiooh-bg-1 img{
        bottom:-150px;
    }
    /* #incentives.block {
        height: 1000px !important;
    } */
}


@media (max-width: 576px) {
    .header-h2 {
        font-size: 7rem;
    }
    
    .header-h1 {
        font-size: 11rem;
    }
    .block {
        height: 100% !important;
    }

    .margin {
        text-align: center;
        margin-top: 20% !important;
        margin-bottom: 5% !important;
    }
    /* #incentives.block {
        height: 800px !important;
    } */

    #about.block {
        height: 300px !important;
    }
    #contact-us.block {
        margin-top: 20% !important;
        padding-bottom: 20%;
    }
}